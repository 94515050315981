import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useNavigatedRoutes } from 'common/hooks';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
import {
  parseConfigsData,
  updateVector3OrPointByRotationXZ,
} from 'containers/world/utils';

import LineSingle from './lineSingle';
import { ShapeComponent } from './ShapeComponent';

export default (props) => {
  const { hotspots, hotspotHistory, autoRotate } = props;
  const [handleUpdateRoute] = useNavigatedRoutes();
  const [spots, setHotspots] = useState([]);
  const prevRot = useSelector((state) => state.prevRotation);
  const timeOut = useRef(null);

  const isAquaStyle = useSelector(({ tour }) => tour.menuStyle === 'aqua');
  const groupsJson = useSelector(state => state.json.groups);

  const spotHistory = useMemo(() => {
    return hotspotHistory.reduce((obj, key) => {
      obj[key] = true;
      return obj;
    }, {});
  }, [hotspotHistory]);

  useEffect(() => {
    setHotspots(
      hotspots.filter((hs) => hs.scene || (hs.media && !hs.media.audio))
    );
  }, [hotspots]);

  const onClickHotspot = (hs) => {
    clearTimeout(timeOut.current);

    if (hs.type === HOTSPOT_TYPE.MEDIA) {
      trackEventByType(EVENT_TYPE.HOTSPOT_FROM_HOTSPOT, hs.media.id);
      handleUpdateRoute(hs.media.id);
    } else {
      trackEventByType(EVENT_TYPE.SCENE_FROM_HOTSPOT, hs.scene.id);
      props.onClickJumpSpot(hs.scene.groupId, hs.scene.id);
      props.toggleHideFootSteps(true);
      timeOut.current = setTimeout(() => {
        props.toggleHideFootSteps(false);
      }, 1000);
    }
  };

  if (!hotspots || !hotspots.length) {
    return null;
  }

  return (
    <>
      {spots.map((hs) => {
        const hsPos = hs.location;
        const currHsPos = updateVector3OrPointByRotationXZ(
          hsPos,
          prevRot,
          false
        );

        const configsData = parseConfigsData(hs.configs);

        return (
          <React.Fragment key={hs._id}>
            {hs?.polygon?.length || configsData?.isShowInline ? (
              <ShapeComponent
                key={hs._id}
                onClickHotspot={() => onClickHotspot(hs)}
                hs={hs}
                configsData={configsData}
              />
            ) : (
              <LineSingle
                key={hs._id}
                currentSceneCoords={props.currentSceneCoords}
                position={currHsPos}
                hotspot={hs}
                type={hs.type}
                scene={hs.scene}
                media={hs.media}
                tour={props.tour}
                groups={groupsJson}
                color={props.brandColor}
                autoRotate={autoRotate}
                toggleRotate={props.toggleRotate}
                visited={spotHistory[hs._id]}
                onClick={() => onClickHotspot(hs)}
                setHotspotHovering={props.setHotspotHovering}
                scope={props.scope}
                currentPano={props.currentPano}
                forceUp={hs.media?.forceUp || (isAquaStyle ? true : false)}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
